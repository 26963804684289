import { useEffect, useRef, useState } from "react";
import PageLoader from "../../Components/Loader/PageLoader";
import { Clipboard, MicOff, Mic, ThumbsDown, ThumbsUp, Volume2 } from "react-feather";
import Helpers from "../../Config/Helpers";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ChatGPTFormatter from "../../Components/ChatgptFormatter";
import MarkdownIt from "markdown-it";
import "../../App.css";

const md = new MarkdownIt();

const Chatbot = () => {
  const { chatid } = useParams();
  const navigate = useNavigate();
  const [chatId, setChatId] = useState(chatid ? chatid : Helpers.generateChatId());
  const [pageLoading, setPageLoading] = useState(false);
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [voice, setVoice] = useState('aura-arcas-en');  
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(new Audio());

  const mediaRecorderRef = useRef(null);
  const timerRef = useRef(null);

  // const toggleRecording = () => {
  //   if (!isRecording) {
  //     navigator.mediaDevices.getUserMedia({ audio: true })
  //       .then(stream => {
  //         mediaRecorderRef.current = new MediaRecorder(stream);
  //         mediaRecorderRef.current.start();
  //         setIsRecording(true);
  //         setIsLoading(true);
  //         timerRef.current = setInterval(() => {
  //           setRecordingTime(prevTime => prevTime + 1);
  //         }, 1000);

  //         mediaRecorderRef.current.ondataavailable = function (e) {
  //           setAudioData(e.data);
  //         };
  //       })
  //       .catch(err => console.error('Error accessing media devices.', err));
  //   } else {
  //     mediaRecorderRef.current.stop();
  //     clearInterval(timerRef.current);
  //     setRecordingTime(0);
  //     setIsRecording(false);
  //     setIsLoading(false);
  //     handleAudioTranscription(audioData);
  //   }
  // };

  // const handleAudioTranscription = (audioBlob) => {
  //   const formData = new FormData();
  //   formData.append('file', audioBlob);

  //   fetch('https://api.deepgram.com/v1/listen', {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': `Token b8a9009ee19e3ed4261ce3d1133ccaaa122b0c56`
  //     },
  //     body: formData
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       const transcript = data.results.channels[0].alternatives[0].transcript;
  //       sendToChatbot(transcript);
  //     })
  //     .catch(error => console.error('Error transcribing audio:', error));
  // };

  // const sendToChatbot = (transcription) => {
  //     messages.length>0?getResponse(transcription):handleData(transcription);
  // };

  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onstart = () => console.log('Speech recognition activated. Please speak.');

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        setUserInput(transcript);
      };

      recognition.onerror = (event) => console.error('Error during recognition:', event.error);

      if (isListening) {
        recognition.start();
      } else {
        recognition.stop();
      }

      return () => recognition.stop();
    }
  }, [isListening]);

  const handleListen = () => setIsListening(!isListening);

  useEffect(() => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play().catch(e => console.error('Error playing audio:', e));
      return () => URL.revokeObjectURL(audioUrl); // Cleanup URL after playing
    }
  }, [audioUrl]);

  const playSpeech = async (msg) => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeAttribute('src');
        audioRef.current.load();
      }
  
      const response = await fetch(`${Helpers.apiUrl}chat/tts`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({ message: msg, voice: voice }),
      });
  
      if (!response.ok) {
          const errorText = await response.text();
          throw new Error('Failed to fetch TTS audio: ' + errorText);
      }
  
      const audioBlob = await response.blob();
      console.log('Received blob with size:', audioBlob.size, 'and type:', audioBlob.type);
  
      if (audioBlob.size > 0) {
          const url = URL.createObjectURL(audioBlob);
          console.log('Generated Blob URL:', url);
  
          // Here, update the source of the audio element managed by audioRef
          audioRef.current.src = url;
          audioRef.current.oncanplay = () => audioRef.current.play();
          audioRef.current.onerror = () => {
              throw new Error('Error playing audio');
          };
      } else {
          console.error('Received empty audio blob.');
      }
    } catch (error) {
        console.error("Error during speech playback:", error);
    }
  };
  

  useEffect(() => {
    if (chatId) {
      getChat();
      navigate(`/user/chatbot/${chatId}`);
    }
  }, [chatId]);

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  const getChat = () => {
    if (chatid) {
      setPageLoading(true);
      axios
        .get(`${Helpers.apiUrl}chat/get/${chatid}`, Helpers.authHeaders)
        .then((response) => {
          setChat(response.data);
          setPageLoading(false);
          if (
            response.data.messages.length === 0 ||
            response.data.chat_message === ""
          ) {
              getFirstResponse();
          } else {
            setMessages(response.data.messages);
            setTimeout(() => {
              scrollToBottom();
            }, 500);
          }
        });
    }
  };

  const getFirstResponse = () => {
    setIsLoading(true);
    let msg = {
      message: "",
      user_id: Helpers.authUser.id,
      chat_id: chat.id,
      is_bot: 1,
    };
    let msgs = messages;
    msgs.push(msg);
    setMessages(msgs);
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    const data = {
      chatid: chatid,
    };
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${Helpers.apiUrl}bot/init-response`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((error) => {
            Helpers.toast("error", error.message);
            setIsLoading(false);
          });
        } else {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          function processText({ done, value }) {
            if (done) {
              setIsLoading(false);
              return;
            }
            let text = decoder.decode(value);
            if (text.endsWith("[DONE]")) {
              text = text.slice(0, -6);
            }
            let withLines = text.replace(/\\n/g, "\n");
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[0].message += withLines;
              return updatedMessages;
            });
            setTimeout(() => {
              scrollToBottom();
            }, 500);
            reader.read().then(processText);
          }
          reader.read().then(processText);
        }
      })
      .catch((error) => {
        console.log("ERROR::", error);
        setIsLoading(false);
      });
  };

  const getResponse = (message=null) => {
    if (message?message:userInput) {
      setIsLoading(true);
      let msg = {
        message: message?message:userInput,
        user_id: Helpers.authUser.id,
        chat_id: chat.id,
        is_bot: 0,
      };
      let msgs = messages;
      msgs.push(msg);
      setMessages(msgs);
      setTimeout(() => {
        scrollToBottom();
      }, 500);
      const data = new FormData();
      data.append("chatid", chatid);
      data.append("input", message?message:userInput);
      addMessage();
      setUserInput("");
      const controller = new AbortController();
      const signal = controller.signal;
      fetch(`${Helpers.apiUrl}bot/response`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: data,
        signal,
      })
        .then((response) => {
          if (!response.ok) {
            response.json().then((error) => {
              Helpers.toast("error", error.message);
              setIsLoading(false);
            });
          } else {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            function processText({ done, value }) {
              if (done) {
                setIsLoading(false);
                return;
              }
              let text = decoder.decode(value);
              if (text.endsWith("[DONE]")) {
                text = text.slice(0, -6);
              }
              let withLines = text.replace(/\\n/g, "\n");
              setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[messages.length - 1].message += withLines;
                return updatedMessages;
              });
              setTimeout(() => {
                scrollToBottom();
              }, 500);
              reader.read().then(processText);
            }
            reader.read().then(processText);
          }
        })
        .catch((error) => {
          console.log("ERROR::", error);
          setIsLoading(false);
        });
    } else {
      Helpers.toast("error", "Can't send without input");
    }
  };

  const addMessage = () => {
    let msg = {
      message: "",
      user_id: Helpers.authUser.id,
      chat_id: chat.id,
      is_bot: 1,
    };
    let msgs = messages;
    msgs.push(msg);
    setMessages(msgs);
  };

  useEffect(() => {
    getChat();
  }, []);

  const handleData = async (is_bot = 0,message=null) => {
    await axios
      .post(
        `${Helpers.apiUrl}chat/save/${chatId}`,
        {
          message: message?message:userInput,
          is_bot: is_bot,
        },
        Helpers.authHeaders
      )
      .then((response) => {
        getResponse();
        navigate(`/user/chatbot/${response.data}`);
      });
  };

  return (
    <>
      <div class="nk-content chatbot-mb">
        <div class="container-xl">
          <div class="nk-content-inner">
            {pageLoading ? (
              <PageLoader />
            ) : (
              <div class="nk-content-body">
                <div class="nk-block">
                  {messages.map((msg, index) => {
                    return (
                      <div
                        key={index}
                        className={`container chat-box ${
                          msg.is_bot == 0 ? "bg-white" : "bot-bubble"
                        }`}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="row align-center">
                              <div className="col-6">
                                <div className="chat-header">
                                  {msg.is_bot == 0 && (
                                    <div>
                                      <img
                                        className="chat-avatar"
                                        src={Helpers.serverImage(
                                          Helpers.authUser.profile_pic
                                        )}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  {msg.is_bot == 1 && (
                                    <div class="media media-middle media-circle text-bg-primary">
                                      <img src="/app/favicon.png" alt="" />
                                    </div>
                                  )}
                                  <span className="chat-user">
                                    <strong>
                                      {msg.is_bot == 1 ? "VoiceChatbot.AI" : "You"}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 text-right">
                                <div className="chat-actions">
                                  <Volume2 className="pointer ml20" size={20} onClick={() => playSpeech(msg.message)} />
                                  <Clipboard className="pointer ml20" size={20} />
                                  <ThumbsUp
                                    className="pointer ml20"
                                    size={20}
                                  />
                                  <ThumbsDown
                                    className="pointer ml20"
                                    size={20}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="chat-divider"></div>
                          </div>
                          <div className="col-12">
                            <div className="message">
                              <ChatGPTFormatter
                                response={msg.message}
                                writing={
                                  messages.length - 1 === index && isLoading
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="nk-footer chat-bottom">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 p0">
              <div className="form-group">
                <div className="form-control-wrap">
                  <textarea
                    className="chatbot-input"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Write your message..."
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-control-wrap">
              <div className="d-flex justify-content-end text-right">
              {!isListening && <button
                  type="submit"
                  className="btn btn-md btn-primary"
                  style={{
                    backgroundColor: "#671AB3",
                    color: "#ffffff",
                    borderRadius: "20px",
                  }}
                  disabled={isLoading}
                  onClick={() => messages.length>0?getResponse():handleData()}
                >
                  <span className="mx-2">Send</span>
                  
                </button>}
                <a 
                  className={`${isListening ? "pulsating" : ""}`}
                  onClick={handleListen}
                  style={!isListening ? { padding: "0.3rem 0 0 0.5rem" } : {}}
                >
                {isListening ? ( <Mic size={20} />  ) : (<MicOff size={20} /> )}
                  {isRecording && <p>{recordingTime} sec</p>}
                </a>
                {/* <a onClick={toggleRecording}>
                  {isRecording ? ( <Mic size={20} className="pulsating" />  ) : (<MicOff size={20} /> )}
                  {isRecording && <p>{recordingTime} sec</p>}
                </a> */}

              </div>
            </div>
          </div>
        </div>
      </div>
      
      {audioUrl && <audio controls style={{display: "none"}} src={audioUrl} autoPlay />}
    </>
  );
};

export default Chatbot;
